import axios from 'axios';
import { getCurrentUser } from '@src/helpers/Utils';

const eCobraApi = axios.create({
    baseURL: 'https://cbabackend.ecobra.es/',
    /* baseURL: 'https://desacbabackend.ecobra.es/', */
    /* baseURL: 'https://cbabackend.ecobra.es/', */

});

eCobraApi.all = axios.all;

eCobraApi.interceptors.request.use(
 (config) => {
   const user = getCurrentUser();
   // eslint-disable-next-line no-param-reassign
   config.headers.Authorization = user
     ? `Bearer ${user.authToken}`
     : undefined;
   return config;
 },
 (error) => Promise.reject(error)
);

eCobraApi.interceptors.response.use(
 (response) => response.data,
 (error) => Promise.reject(error)
);

eCobraApi.signInWithEmailAndPassword = (email, password) => {
 return eCobraApi.post('usuario/login', {
   username: email,
   password,
 });
};

eCobraApi.checkAuth = () => {
 return eCobraApi.get('token/refresh');
};

eCobraApi.getCatCompanys = () => {
 return eCobraApi.get('tb-empresa');
};

eCobraApi.getCatCountries = () => {
 return eCobraApi.get('pais');
};

eCobraApi.getCatCurrency = () => {
 return eCobraApi.get('cat-moneda');
};

eCobraApi.getCatUnits = () => {
 return eCobraApi.get('cat-unidad');
};

eCobraApi.getCatIndirectCosts = () => {
 return eCobraApi.get('cat-categoria-coste-indirecto');
};

eCobraApi.getCatSupliers = () => {
 return eCobraApi.get('cat-proveedor');
};

eCobraApi.getProjects = () => {
 return eCobraApi.get('usuario/proyectos');
};

eCobraApi.addProjectPresuposition = (payload) => {
 return eCobraApi.post('proyecto/importacion-excel-proyecto', payload);
};

eCobraApi.getProjectPresupposition = (idProject) => {
 return eCobraApi.get('presupuesto/detalle', {
   params: { id: idProject },
 });
};

eCobraApi.setProjectPresuppositionDirectCost = (payload) => {
 return eCobraApi.post('estudio-inicial/estimacion-coste-directo', payload);
};

eCobraApi.updateProjectPresuppositionDirectCost = (payload) => {
 return eCobraApi.post('estudio-inicial/modificacion-coste-directo', payload);
};

eCobraApi.deleteProjectPresuppositionDirectCost = (idDirectCost) => {
 return eCobraApi.delete('estudio-inicial/delete-coste-directo', {
   params: { id: idDirectCost },
 });
};

eCobraApi.setProjectPresuppositionIndirectCost = (payload) => {
 return eCobraApi.post('estudio-inicial/estimacion-coste-indirecto', payload);
};

eCobraApi.updateProjectPresuppositionIndirectCost = (payload) => {
 return eCobraApi.post('estudio-inicial/estimacion-coste-indirecto', payload);
};

eCobraApi.deleteProjectPresuppositionIndirectCost = (idIndirectCost) => {
 return eCobraApi.delete('estudio-inicial/delete-coste-indirecto', {
   params: { id: idIndirectCost },
 });
};

eCobraApi.getProjectProductions = (idProject) => {
 return eCobraApi.get('produccion/produccion-proyecto-completa', {
   params: { id: idProject },
 });
};

eCobraApi.closeProjectProductionMonth = (id) => {
 return eCobraApi.post('timeline/cierra-timeline-actual', {
   id_proyecto: id,
 });
};

eCobraApi.setProjectProduction = (payload) => {
 return eCobraApi.post('produccion/add-produccion', payload);
};

eCobraApi.getProjectTimeline = (idProject) => {
 return eCobraApi.get('timeline/obtener-timelines-proyecto', {
   params: { id: idProject },
 });
};

eCobraApi.getProjectIndirectCostsAll = ({ idProject }) => {
 return eCobraApi.get('coste-indirecto-timeline/indirectos-proyecto', {
   params: { id: idProject },
 });
};

eCobraApi.getProjectIndirectCostsCurrentMonth = ({ idProject }) => {
 return eCobraApi.get('coste-indirecto-timeline/indirectos-mes-actual', {
   params: { id: idProject },
 });
};

eCobraApi.getProjectIndirectCostsAtMonth = ({ idProject, idTimeline }) => {
 return eCobraApi.get('coste-indirecto-timeline/indirectos-mes', {
   params: { id: idProject, idtimeline: idTimeline },
 });
};

eCobraApi.setProjectIndirectCost = (payload) => {
 return eCobraApi.post('coste-indirecto-timeline/add-indirecto', payload);
};

eCobraApi.updateProjectIndirectCost = (costData) => {
 return eCobraApi.put('coste-indirecto-timeline/add-indirecto', costData);
};

eCobraApi.deleteProjectIndirectCost = ({ idProject, idCost }) => {
 return eCobraApi.delete('coste-indirecto-timeline/delete-indirecto', {
   params: { id_proyecto: idProject, id_coste_indirecto: idCost },
 });
};

eCobraApi.getProjectDeliveryNotesAll = ({ idProject }) => {
 return eCobraApi.get('albaran-timeline/albaranes-proyecto', {
   params: { id: idProject },
 });
};

eCobraApi.getProjectDeliveryNotesCurrentMonth = ({ idProject }) => {
 return eCobraApi.get('albaran-timeline/albaranes-mes-actual', {
   params: { id: idProject },
 });
};

eCobraApi.getProjectDeliveryNotesAtMonth = ({ idProject, idTimeline }) => {
 return eCobraApi.get('albaran-timeline/albaranes-mes', {
   params: { id: idProject, idtimeline: idTimeline },
 });
};

eCobraApi.setProjectDeliveryNote = (payload) => {
 return eCobraApi.post('albaran-timeline/add-albaran', payload);
};

eCobraApi.updateProjectDeliveryNote = (deliveryData) => {
 return eCobraApi.put('albaran-timeline/add-albaran', deliveryData);
};

eCobraApi.deleteProjectDeliveryNote = ({ idProject, idDelivery }) => {
 return eCobraApi.delete('albaran-timeline/delete-albaran', {
   params: { id_proyecto: idProject, id_albaran: idDelivery },
 });
};

eCobraApi.getProjectSubcontractorsAll = ({ idProject }) => {
 return eCobraApi.get('subcontratista-timeline/subcontratistas-proyecto', {
   params: { id: idProject },
 });
};

eCobraApi.getProjectSubcontractorsCurrentMonth = ({ idProject }) => {
 return eCobraApi.get('subcontratista-timeline/subcontratistas-mes-actual', {
   params: { id: idProject },
 });
};

eCobraApi.getProjectSubcontractorsAtMonth = ({ idProject, idTimeline }) => {
 return eCobraApi.get('subcontratista-timeline/subcontratistas-mes', {
   params: { id: idProject, idtimeline: idTimeline },
 });
};

eCobraApi.setProjectSubcontractor = (payload) => {
 return eCobraApi.post('subcontratista-timeline/add-subcontratista', payload);
};

eCobraApi.updateProjectSubcontractor = (subcontractorData) => {
 return eCobraApi.put(
   'subcontratista-timeline/add-subcontratista',
   subcontractorData
 );
};

eCobraApi.deleteProjectSubcontractor = ({ idProject, idSubcontractor }) => {
 return eCobraApi.delete('subcontratista-timeline/delete-subcontratista', {
   params: { id_proyecto: idProject, id_subcontratista: idSubcontractor },
 });
};

eCobraApi.closeInitialStudy = (id) => {
 return eCobraApi.post('proyecto/cerrar-estudio-inicial', { id });
};

eCobraApi.approveInitialStudy = (id) => {
 return eCobraApi.post('proyecto/aprobar-estudio-inicial', { id });
};

eCobraApi.reviseInitialStudy = (id) => {
 return eCobraApi.post('proyecto/revisar-estudio-inicial', { id });
};

eCobraApi.closeRevisedInitialStudy = (id) => {
 return eCobraApi.post('proyecto/aprobar-revision-estudio-inicial', { id });
};

eCobraApi.startConstruction = (id) => {
 return eCobraApi.post('proyecto/empezar-obra', { id });
};

eCobraApi.cancelConstruction = (id) => {
 return eCobraApi.post('proyecto/cancelar-proyecto', { id });
};

eCobraApi.finishConstruction = (id) => {
 return eCobraApi.post('proyecto/finalizar-obra', { id });
};

eCobraApi.addProjectExtension = (payload) => {
 return eCobraApi.post('proyecto/importacion-excel-ampliacion-proyecto', payload);
};
export default eCobraApi;
